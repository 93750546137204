<template>
  <div class="container">
    <div class="column form-wrap is-4">
      <div class="box" id="login">
        <div class="cst-logo m-4 has-text-centered">
          <img
            src="../../assets/logo.png"
            style="height: 36px"
            alt="cst-logo"
          />
        </div>
        <div class="mt-1 mb-4 has-text-centered">
          Please enter a new password for your account.
        </div>

        <b-field label="">
          <b-input
            type="password"
            v-model="password"
            placeholder="New password"
            :readonly="isLoading"
          ></b-input>
        </b-field>
        <b-field label="">
          <b-input
            type="password"
            v-model="re_password"
            placeholder="Confirm new password"
            :readonly="isLoading"
          ></b-input>
        </b-field>

        <div class="field mt-5">
          <div class="control">
            <b-button
              type="is-primary is-fullwidth mb-3"
              @click="submitForm"
              :loading="isLoading"
            >
              Reset Password</b-button
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "UserResetConfirm",
  data() {
    return {
      password: "",
      re_password: "",
      url: "/auth/users/reset_password_confirm/",
      error: false,
      errorMessage: "",
    };
  },
  computed: {
    isLoading() {
      return this.$store.getters.isLoading;
    },
  },
  methods: {
    async performReset() {
      await axios
        .post(this.url, {
          uid: this.$route.params.uid,
          token: this.$route.params.token,
          new_password: this.password,
          re_new_password: this.re_password,
        })
        .then((response) => {
          if (response.status === 204) {
            this.error = false;
            this.errorMessage = "";
            this.$buefy.toast.open({
              message: "Password reset successful. Please login to continue.",
              type: "is-success",
              queue: false,
              duration: 5000,
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            if (error.response.data.new_password) {
              this.error = true;
              this.errorMessage = error.response.data.new_password[0];
            } else if (error.response.data.re_new_password) {
              this.error = true;
              this.errorMessage = error.response.data.re_new_password[0];
            } else if (error.response.data.uid) {
              this.error = true;
              this.errorMessage = error.response.data.uid[0];
            } else if (error.response.data.token) {
              this.error = true;
              this.errorMessage = error.response.data.token[0];
            }
          }
        });
    },
    formValid() {
      return this.password && this.password === this.re_password;
    },
    submitForm() {
      if (this.formValid()) {
        this.$store.dispatch("setLoading", true);
        this.error = false;
        this.errorMessage = "";
        this.performReset().then(() => {
          if (this.error) {
            this.$buefy.toast.open({
              message: this.errorMessage,
              type: "is-danger",
            });
            this.$store.dispatch("setLoading", false);
          } else {
            setTimeout(() => {
              this.$store.dispatch("setLoading", false);
              this.$router.push({
                name: "UserLogin",
              });
            }, 2000);
          }
        });
      } else {
        this.error = true;
        if (this.password !== this.re_password) {
          this.errorMessage = "Passwords do not match";
        } else {
          this.errorMessage = "Please fill in all the required fields.";
        }
        this.$buefy.toast.open({
          message: this.errorMessage,
          type: "is-danger",
        });
      }
    },
  },
};
</script>

<style scoped>
.form-wrap {
  margin: 0 auto;
  margin-top: 10%;
}
</style>
